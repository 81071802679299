<template>
  <v-container>
    <!-- pageHeader -->
    <v-row>
      <v-col cols="8">
        <h1>Alternative Destinations</h1>
      </v-col>
      <v-col cols="4" align="end">
        <v-btn
          color="primary"
          depressed
          @click="$router.go(-1)"
          :loading="$store.state.loading"
          :disabled="$store.state.loading"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Back</v-btn
        >
      </v-col>
    </v-row>
    <!-- pageHeader -->
    <!-- Alternative destination -->
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  label="Search Destination"
                  dense
                  clearable
                  outlined
                  hide-details
                  v-model="search"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-col cols="8" md="7" class="d-flex justify-end">
                <v-btn
                  small
                  outlined
                  color="primary"
                  :loading="$store.state.loading"
                  :disabled="$store.state.loading"
                  @click="packageDialog = true"
                  >Package listing</v-btn
                >
                <v-btn
                  class="ms-2"
                  small
                  outlined
                  color="primary"
                  :loading="$store.state.loading"
                  :disabled="$store.state.loading"
                  @click="dialog = true"
                  >Add Destination</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            dense
            :headers="[
              { text: 'Name', value: 'name'},
              { text: 'Guests/Nights', value: 'guestsNights'  ,sortable:false},
              { text: 'Inventorys', value: 'inventoryList'  ,sortable:false},
              { text: 'Edit', value: 'edit'  ,sortable:false},
              { text: 'Delete', value: 'delete' ,sortable:false },
            ]"
            :items="alternativeDestinationsComputed"
            :items-per-page="-1"
            :loading="loading"
            :search="search"
            @click:row="onViewDestination"
          >
            <template v-slot:[`item.guestsNights`]="{ item }">
              {{ item.nights }} Nights, 1-{{ item.guests }} Guests
            </template>
            <template v-slot:[`item.inventoryList`]="{ item }">
              {{ item.inventoryId ? item.inventoryId.length : "0" }} inventorys
              Assign
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-list-item
                class="action-icon"
                @click="
                  (event) => {
                    event.stopPropagation();
                    edit(item);
                  }
                "
              >
                <!-- <v-list-item-icon> -->
                <v-icon small> mdi-pencil </v-icon>
                <!-- </v-list-item-icon> -->
              </v-list-item>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-list-item
                class="action-icon"
                @click="
                  (event) => {
                    event.stopPropagation();
                    deleteItem(item);
                  }
                "
              >
                <!-- <v-list-item-icon> -->
                <v-icon small> mdi-delete </v-icon>
                <!-- </v-list-item-icon> -->
              </v-list-item>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete"
            :loading="loading"
            :disabled="loading"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
            :loading="loading"
            :disabled="loading"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="packageDialog">
      <v-dialog
        v-model="packageDialog"
        width="30%"
        transition="dialog-bottom-transition"
      >
        <v-card tile>
          <v-card-text class="text--primary">
            <!-- form -->
            <v-form ref="form" @submit.prevent="save">
              <v-card-title>
                <span class="text-h5">Package listing</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row class="mt-2">
                    <v-col cols="12" md="12" class="d-flex">
                      <v-autocomplete
                        :loading="loading"
                        :disabled="loading"
                        :items="retailPackages"
                        item-text="packageCode"
                        v-model.trim="destinationViewOnlyPackage.packages"
                        label=" Package listing"
                        :rules="[(v) => !!v || 'Field Required']"
                        multiple
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="toggle"
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  destinationViewOnlyPackage.packages.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                              >
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 3">
                            <span>{{ item.packageCode }}</span>
                          </v-chip>
                        </template></v-autocomplete
                      >
                      <span
                        v-if="destinationViewOnlyPackage.packages.length > 3"
                        class="grey--text des-length"
                      >
                        +{{ destinationViewOnlyPackage.packages.length - 3 }}
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="packageDialog = false"
                  :loading="loading"
                  :disabled="loading"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="savePackages"
                  :loading="loading"
                  :disabled="loading"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="dialog">
      <v-dialog
        v-model="dialog"
        persistent
        width="50%"
        transition="dialog-bottom-transition"
      >
        <v-card tile>
          <v-card-text class="text--primary">
            <!-- form -->
            <v-form ref="form" @submit.prevent="save">
              <v-card-title>
                <span class="text-h5">{{ formTitle }} </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row class="mt-2">
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        :rules="[(v) => !!v || 'Field Required']"
                        :loading="loading"
                        :disabled="loading"
                      ></v-text-field></v-col
                    ><v-col cols="12" md="6">
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        v-model.trim="editedItem.nights"
                        label="Number of Nights"
                        :rules="[isNumberOnly]"
                      >
                      </v-text-field>
                      </v-col
                    >
                    <v-col cols="12" md="6"
                      >
                      <v-text-field
                        :loading="loading"
                        :disabled="loading"
                        v-model.trim="editedItem.guests"
                        label="Guest"
                        :rules="[isNumberOnly]"
                      >
                      </v-text-field>
                      </v-col
                    >
                    <v-col cols="12" md="6" class="d-flex"
                      ><v-autocomplete
                        :loading="loading"
                        :disabled="loading"
                        :items="inventoryProperty"
                        :rules="[(v) => !!v || 'Field Required']"
                        item-text="id"
                        v-model.trim="editedItem.inventoryId"
                        label="Assign Inventorys"
                        multiple
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @mousedown.prevent
                            @click="inventoryToggle"
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  editedItem.inventoryId.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                              >
                                {{ inventoryIcon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 3">
                            <span>{{ item.id }}</span>
                          </v-chip>
                        </template></v-autocomplete
                      >
                      <span
                        v-if="editedItem.inventoryId.length > 3"
                        class="grey--text des-length"
                      >
                        +{{ editedItem.inventoryId.length - 3 }}
                      </span>
                    </v-col></v-row
                  >

                  <v-row class="mt-2">
                    <v-col cols="12" md="6">
                      <v-img
                        v-if="typeof editedItem.image == 'string'"
                        max-height="212"
                        max-width="291"
                        :src="editedItem.image"
                      ></v-img>
                      <v-img
                        v-else-if="editedItem.image"
                        max-height="212"
                        max-width="291"
                        :src="editedItem.image.downloadUrl"
                      ></v-img>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-btn
                        color="primary"
                        :style="{ width: `100%` }"
                        :disabled="loading"
                        @click="$refs.fileInput.click()"
                        :loading="loading"
                      >
                        Upload
                        {{
                          docButtonDisable || editedItem.image
                            ? "new image"
                            : "a image"
                        }}
                        <input
                          ref="fileInput"
                          type="file"
                          accept=".jpg,.jpeg"
                          style="display: none"
                          @change="onFileSelected"
                        />
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                  :loading="loading"
                  :disabled="loading"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  :loading="loading"
                  :disabled="loading || !editedItem.image"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-form></v-card-text
          ></v-card
        ></v-dialog
      >
    </div>
  </v-container>
</template>

<script>
import { portalsApp, mainApp } from "@/firebase";
export default {
  components: {},
  firestore: {
    retailPackages: portalsApp
      .firestore()
      .collection("retailPackages")
      .orderBy("packageCode"),

    inventoryProperty: portalsApp
      .firestore()
      .collection("inventoryProperties")
      .orderBy("id"),

    alternativeDestinations: portalsApp
      .firestore()
      .collection("alternativeDestinations"),

    destinationViewOnlyPackage: portalsApp
      .firestore()
      .collection("destinationViewOnlyThesePackages")
      .doc("Rvopq36MgxI8BJ153qNF"),
  },



  data: () => ({
    packageDialog: false,
    search: "",
    alternativeDestinations: [],
    destinationViewOnlyPackage: {},
    retailPackages: [],
    inventoryProperty: [],
    loading: false,
    docName: "",
    editedIndex: -1,
    editedItem: {
      inventoryId:[],
    },
    dialog: false,
    dialogDelete: false,
  }),
  watch: {
    async packageDialog(val) {
      if (val == false) {
        let pack = await portalsApp
          .firestore()
          .collection("destinationViewOnlyThesePackages")
          .doc("Rvopq36MgxI8BJ153qNF")
          .get();
        this.destinationViewOnlyPackage = pack.data();
      }
    },
    destinationViewOnlyPackage(val) {
      // console.log("selected", val);
    },
  },
  computed: {
    isNumberOnly() {
      return (v) => {
        if (!v) return "Field Required";
        if (!/^[0-9]+$/.test(v))return "Numbers Only"
        return true;
      }
    },
    alternativeDestinationsComputed(){
      let destinations = this.alternativeDestinations
      destinations = destinations.map(el=>{
        el.inventoryId =  typeof el.inventoryId == "string" ? JSON.parse(el.inventoryId) :el.inventoryId ;
        return el;
      })
    
      return destinations;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Destination" : "Edit Destination";
    },
    docButtonDisable() {
      return this.docName !== "";
    },
    likesAllPackage() {
      return (
        this.destinationViewOnlyPackage.packages.length ===
        this.retailPackages.length
      );
    },
    likesSomePackage() {
      return (
        this.destinationViewOnlyPackage.packages.length > 0 &&
        !this.likesAllPackage
      );
    },
    icon() {
      if (this.likesAllPackage) return "mdi-close-box";
      if (this.likesSomePackage) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    likesSomeInventory() {
      return this.editedItem.inventoryId.length > 0 && !this.likesAllInventory;
    },
    inventoryIcon() {
      if (this.likesAllInventory) return "mdi-close-box";
      if (this.likesSomeInventory) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllInventory() {
      return (
        this.editedItem.inventoryId.length === this.inventoryProperty.length
      );
    },
  },
  methods: {
    inventoryToggle() {
      this.$nextTick(() => {
        if (this.likesAllInventory) {
          this.editedItem.inventoryId = [];
        } else {
          this.editedItem.inventoryId = this.inventoryProperty.map(el=> el.id).slice();
        }
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllPackage) {
          this.destinationViewOnlyPackage.packages = [];
        } else {
          this.destinationViewOnlyPackage.packages =
            this.retailPackages.map(el=> el.id).slice();
        }
      });
    },
    async savePackages() {
      this.loading = true;
      await portalsApp
        .firestore()
        .collection("destinationViewOnlyThesePackages")
        .doc("Rvopq36MgxI8BJ153qNF")
        .update(this.destinationViewOnlyPackage);

      this.loading = false;
      this.packageDialog = false;
    },
    onViewDestination(destination) {
      this.$router.push(`alternative-destinations/${destination.id}`);
    },
    deleteItem(item) {
      this.editedIndex = this.alternativeDestinations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.loading = true;
      let doc = portalsApp
        .firestore()
        .collection("alternativeDestinations")
        .doc(this.alternativeDestinations[this.editedIndex].id);
      await doc.delete();
      this.closeDelete();
      this.loading = false;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    edit(item) {
      this.editedIndex = this.alternativeDestinations.indexOf(item);
      this.editedItem = {...item} 
      this.docName = item.image;
      this.dialog = true;
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      let img = {
        file: file,
        name: file.name.replace(" ", "-"),
        downloadUrl: URL.createObjectURL(file),
        dateSubmitted: new Date().toISOString(),
      };
      this.editedItem.image = img;
      this.docName = event.target.files[0].name;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({inventoryId:[]});
        this.editedIndex = -1;
      });
    },
    async save() {
      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }
      await this.$store.dispatch("setLoading", true);
      this.loading = true;
      this.editedItem.matchPackage = this.editedItem.name.split(/[,/]/)[0];
      if (this.editedIndex > -1) {
        if (
          this.alternativeDestinations[this.editedIndex].image ==
          this.editedItem.image
        ) {
          this.editedItem.inventoryId = JSON.stringify(this.editedItem.inventoryId);
          let doc = portalsApp
            .firestore()
            .collection("alternativeDestinations")
            .doc(this.alternativeDestinations[this.editedIndex].id);

          await doc.update(this.editedItem);
          this.loading = false;
        } else {
          const img = this.editedItem.image;
          const imgRef = mainApp
            .storage()
            .ref(`alternativeDestinations/${img.name}`);
          const pdfUploaded = await imgRef.put(img.file);
          this.editedItem.image = await pdfUploaded.ref.getDownloadURL();
          delete img.file;
          this.editedItem.inventoryId = JSON.stringify(this.editedItem.inventoryId);
          let doc = portalsApp
            .firestore()
            .collection("alternativeDestinations")
            .doc(this.alternativeDestinations[this.editedIndex].id);
          await doc.update(this.editedItem);
          this.loading = false;
        }
      } else {
        const img = this.editedItem.image;
        const imgRef = mainApp
          .storage()
          .ref(`alternativeDestinations/${img.name}`);
        const pdfUploaded = await imgRef.put(img.file);
        this.editedItem.image = await pdfUploaded.ref.getDownloadURL();
        delete img.file;
        this.editedItem.inventoryId = JSON.stringify(this.editedItem.inventoryId);
        await portalsApp
          .firestore()  
          .collection("alternativeDestinations")
          .add(this.editedItem);
        this.loading = false;
      }
      await this.$store.dispatch("setLoading", false);
      this.close();
    },
  },
};
</script>

<style>
tr {
  cursor: pointer;
}
.action-icon {
  width: fit-content;
  /* min-height: 0; */
  padding: 0 10px;
}
.des-length {
  padding: 25px 0px;
  padding-left: 5px;
  font-size: 0.9rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
  font-family: "Roboto", sans-serif !important;
}
</style>
